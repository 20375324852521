<template>
  <!-- 用户通知列表单个通知显示弹窗 -->
  <div>
    <Dialog ref="dialog" :config="config">
      <div class="InformBounced ql-editor">
        <h1>{{ item.title }}</h1>
        <p class="timeBox">{{ item.created_at }}</p>
        <!-- 显示不适用于组件的样式的通知 -->
        <div class="con" v-html="item.content" v-if="item.is_email_notice"></div>
        <InFormDetailEmail :content="item.content" v-else />
      </div>
    </Dialog>
  </div>
</template>
<script>
import Dialog from "../../../../components/public/Dialog";
import InFormDetailEmail from './InFormDetailEmail.vue'
export default {
  data() {
    return {
      config: {
        top: "20vh",
        width: "1200px",
        title: "",
        btnTxt: [this.$fanyi("关闭")],
      },
      informList: [],
    };
  },
  components: {
    Dialog,
    InFormDetailEmail
  },
  computed: {
    item() {
      return this.$parent.readItem;
    },
  },
  mounted() { },
  methods: {
    open() {
      this.$refs.dialog.open(
        "",
        () => { },
        () => { }
      );
    },
  },
};
</script>
<style lang='scss' scoped='scoped'>
.InformBounced {
  background: #fafafa;
  margin: 0 auto;
  border-radius: 10px;
  width: 1120px;
  min-height: 600px;
  padding: 20px 40px 40px;
  background-color: #fafafa;
  overflow: visible;

  h1 {
    font-size: 20px;
    font-weight: bold;
    line-height: 30px;

    margin-bottom: 10px;
    text-align: center;
  }

  .timeBox {
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    margin-bottom: 20px;
  }

  .con {
    line-height: 20px;
    font-size: 14px;
  }
}

/deep/.el-dialog {
  margin-top: 10vh !important;
}

/deep/.el-dialog__body {
  padding: 0 30px !important;
}
</style>